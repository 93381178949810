import type { TypeCardPriceElement } from "./TypeCardPrice";

export const membershipPrice: TypeCardPriceElement = {
  price: 1440,
};
export const typeCardNonMemberPremiumPrice: TypeCardPriceElement = {
  price: 299,
  prefix: "Ikke-medlem",
};
export const typeCardNonMemberStandardPrice: TypeCardPriceElement = {
  price: 149,
  prefix: "Ikke-medlem",
};
export const typeCardNonMemberSimplePrice: TypeCardPriceElement = {
  price: 49,
  prefix: "Ikke-medlem",
};
export const typeCardMemberPrice: TypeCardPriceElement = {
  price: 0,
  prefix: "Medlem",
  highlight: true,
};
